@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.App {
  text-align: center;
  background-color: #f8f9fb;
  min-height: 100vh;
  overflow: hidden;
}
.modal-open {
  overflow: unset !important;
  padding: 0 !important;
  margin: 0 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.cancel {
  color: #777777 !important;
  background: none;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #c4cece;
  box-shadow: 0px 3px 6px #00000029;
}
.cancel1 {
  background: #40bbbd;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  color: #ffffff;
  border: none;
}
.reject {
  background: #40bbbd;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  border: none;
}
.submit {
  background: #b3c800;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  border: none;
}
.pop-up-submit {
  background: #40bbbd;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  border: none;
}
button:focus {
  box-shadow: none !important;
}
.image-hover:hover {
  cursor: pointer;
}
.three-dot-fallback {
  justify-content: center;
  position: absolute;
  top: 35%;
  right: 47.2vw;
}
.dialoge-image {
  display: block;
  margin: auto;
  width: 107px;
  height: 96px;
}
@media (min-width: 280px) and (max-width: 359px) {
  .submit {
    height: 30px;
    font-size: 9px;
  }
  .cancel {
    font-size: 9px;
    height: 30px;
  }
  .reject {
    font-size: 9px;
    height: 30px;
  }
}

@media (min-width: 360px) and (max-width: 540px) {
  .submit {
    font-size: 10px;
  }
  .cancel {
    font-size: 10px;
  }
  .reject {
    font-size: 10px;
  }
}
.p-divider-horizontal {
  width: 91%;
  margin: auto auto 1rem auto;
  border: 1px solid #e1e1e1;
}
textarea {
  resize: none !important;
}
.link-view {
  border: none;
  background: none;
  color: #40bbbd;
}
.col-label {
  font-size: 1rem;
  color: #85a2a2;
}
.pop-msg-lable {
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  margin: 2rem 0rem;
}
.p-dialog-draggable .p-dialog-header {
  padding: 1rem 1rem 0px 1rem;
}
.pop-up-ok-btn {
  background-color: #b3c800;
  width: 80px;
  height: 40px;
  border: 2px solid #ffffff;
  box-shadow: 0px 3px 6px #00000014;
  border-radius: 20px;
}
.p-component {
  font-family: "Poppins";
}
.p-dialog .p-dialog-header {
  border-radius: 20px 20px 0px 0px;
}
.p-dialog .p-dialog-footer {
  border-radius: 0px 0px 20px 20px;
}
.p-dialog .p-dialog-content:last-of-type {
  border-radius: 0px 0px 20px 20px;
}
.dropdwon-fildset {
  border: 1px solid #c4cece;
  border-radius: 10px;
  width: 100%;
}
.dropdwon-legend {
  float: none;
  color: #c4cece;
  width: auto;
  font-size: 14px;
  margin-bottom: 0px;
  text-align: left;
  margin-left: 13px;
}
.p-dropdown-trigger::before {
  background-image: url(./Images/arrow_drop_down_FILL0_wght300_GRAD0_opsz24.svg);
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none;
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #666666;
  background: #ecffff;
}
@media (min-width: 280px) and (max-width: 540px) {
  .big-sidebar {
    z-index: 11;
  }
  .main-container {
    margin-left: unset !important;
  }
}
.css-ahj2mt-MuiTypography-root,
.css-9l3uo3 {
  font-family: "Poppins", sans-serif !important;
}
.star {
  color: #ff0000;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #666666;
  background: #ecffff;
}
.cgp-sdo-btns {
  align-items: center;
  display: flex;
  justify-content: end;
  gap: 1rem;
}
.sf-word-wrap {
  word-wrap: break-word;
}
.community_class_remover {
  text-align: left;
  background: none;
  border: none;
}
.fh-font-size-14 {
  font-size: 14px;
}
